import React from 'react';
import bombIcon from '../../Images/bomb.png';

function ErrorScreen(props){
    return (
        <div className="error-container" data-testid="error-container">
          <div className="error-container__error-message-wrapper" data-testid="error-message-wrapper">
            <img src={bombIcon} alt="Bomb icon" data-testid="bomb-icon"/>
            <h2 className="text header" data-testid="error-header">Something Went Boom!</h2>
            <h4 className="more-info text" data-testid="more-info">More Info:</h4>
            <h3 className="text" data-testid="error-message">{props.errorMessage.toString()}</h3>
          </div>
        </div>
    )
}

export default ErrorScreen;
