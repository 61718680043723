import { observable, action, toJS } from 'mobx';
import { NotificationManager } from '../../Components/popups/react-notifications/index';
import JSZip from 'jszip';

import Helper from '../../Helpers/helper';
import RootModel from '../RootModel';
import FileManagerModel from '../FileManagerModel';
import PopUpModel from '../PopUpModel';
import FileBinModel from '../FileBinModel';
import DatePickerModel from '../DatePickerModel';
import UploadZipModel from './UploadZipModel.js';

import { Sections } from '../Structures';
import fetchData from '../../Services/fetchData';
import { DEAL_ID, SECTION, THREAD_ID } from '../../constants/api';
import { getMonthReverce } from '../../Helpers/convertData';

class UploadModel {
  @observable uploadData = null;
  @observable noFilesInDocumentManager = true;
  @observable shouldReloadPage = false;
  @observable onUploadSuccess = null;
  @observable onUploadBegin = null;
  @observable isLoading = false;
  @observable isBankLoading = false;

  @action setOnUploadSuccess = uploadSuccessMethod => {
    this.onUploadSuccess = uploadSuccessMethod;
  };

  @action setOnUploadBegin = uploadBeginMethod => {
    this.onUploadBegin = uploadBeginMethod;
  };

  @action setUploadData(data) {
    this.uploadData = data;
  }

  @action setPageReloadState(state) {
    this.shouldReloadPage = state;
  }

  @action
  setLoadingState = state => {
    this.isLoading = state;
  };

  @action
  setBankLoadingState = state => {
    this.isBankLoading = state;
  };

  // handle Files Upload
  @action
  async handleFilesUpload(
    files,
    dest,
    subSectionId,
    otherDealsCopy,
    reloadPageOnComplete = false,
    additionalData = {},
  ) {
    this.setLoadingState(true);

    try {
      if (Helper.checkSectionIsDeprecated(subSectionId)) {
        PopUpModel.closeAll();
        NotificationManager.warning(`Can't be uploaded in this section`);
        UploadZipModel.setUnzipData();
        this.setLoadingState(false);
        return;
      }

      if (files.length > 0) {
        if (additionalData.onlySingleFileAllowed && files.length > 1) {
          PopUpModel.closeAll();
          NotificationManager.error(`Cannot upload more than 1 file`);
          UploadZipModel.setUnzipData();
          this.setLoadingState(false);
          return;
        }

        this.setUploadData({
          files,
          dest,
          subSectionId,
          otherDealsCopy,
          additionalData,
        });

        const zipExtensions = ['x-zip-compressed', 'zip'];
        const fileType = files[0].type.split('/')[1];
        const isZip = zipExtensions.includes(fileType);

        if (reloadPageOnComplete) {
          this.setPageReloadState(true);
        }

        if (isZip && (!UploadZipModel.unzipData || UploadZipModel.unzipConfirmed === null)) {
          const zip = new JSZip();
          await zip
            .loadAsync(files[0]) // determine if folders in zip
            .then(async zipObj => {
              if (UploadZipModel.unzipConfirmed === null) {
                UploadZipModel.setUnzipData({
                  isFolderInZip: UploadZipModel.determineFolderInZip(zipObj),
                  dest,
                });
                return PopUpModel.open('unzipConfirm');
              }
            });
        }

        if (!isZip) {
          PopUpModel.closeAll();

          await this.uploadOrdinaryFiles(files, dest, subSectionId, otherDealsCopy, additionalData.parentDest);
        }
      }

      PopUpModel.closeAll();
      UploadZipModel.setUnzipData();
    } catch (error) {
      console.error('Error during file upload:', error);
      NotificationManager.error(`File upload failed`);
    }
  }

  @action async uploadSingleFile(file, type, dest, parentId) {
    if (type === 'BankAccountDetail' && !Helper.isBankMonthToDate(dest, parentId)) {
      let bank = RootModel.Banks.find(subSection => subSection.id.toString() === parentId);
      if (bank.isDeletable) {
        DatePickerModel.toggleDatePicker(parentId);
        this.setUploadData({
          files: [file],
          id: parentId,
          subSectionId: parentId,
          isDatePickerShown: true,
        });
      } else {
        this.setUploadData({
          files: [file],
          id: parentId,
          subSectionId: parentId,
          isDatePickerShown: false,
        });
        this.uploadBankDocuments();
      }
    } else {
      const data = {
        parent_type: type,
        name: file.name,
        parent_id: parentId,
        document_type: dest,
      };

      let document64 = (await this.toBase64(file)).split('base64,');
      data.document = document64[1];
      this.onUploadBegin && this.onUploadBegin();
      fetchData('documents/create', data)
        .then(res => res.json())
        .then(res => {
          RootModel.getDocuments();
          RootModel.FileBinFiles.push(res);
          NotificationManager.success(`${file.title || file.name} has been successfully uploaded!`);
          this.onUploadSuccess && this.onUploadSuccess();
        })
        .catch(err => {
          NotificationManager.error(`Can not upload ${file.name}: ${err.message}`);
          UploadZipModel.setUnzipData();
          return;
        })
        .finally(() => {
          if (this.shouldReloadPage) {
            window.location.reload();
            this.setPageReloadState(false);
          }
        });
    }
  }

  @action uploadOrdinaryFiles(files, dest, subSectionId, otherDealsCopy, parentDest) {
    if (parentDest === 'Banks') {
      this.uploadBankSubDocs();
    } else if (dest === 'Banks' && !Helper.isBankMonthToDate(dest, subSectionId)) {
      let subSection = RootModel[dest].find(subSection => subSection.id == subSectionId);

      if (subSection.isDeletable) {
        this.setLoadingState(false);
        DatePickerModel.toggleDatePicker(subSectionId);
      } else {
        this.uploadBankDocuments();
      }
    } else {
      this.uploadFiles();
    }
  }

  // UPLOADING
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  convertDestinationToProp(dest, subSectionId) {
    if (dest !== 'FileBinFiles') {
      for (let i = 0; i < RootModel[dest].length; i++) {
        if (subSectionId.toString() == RootModel[dest][i].id.toString()) {
          let item = RootModel[dest][i];
          if (item.name == 'Funding_call') {
            return 'Funding_call';
          } else if (item.name == 'Certificate') {
            return 'Certificate';
          }

          return typeof Sections[dest][i] === 'object' && Sections[dest][i].hasOwnProperty('Contracts')
            ? 'Signed_contract_coj'
            : Sections[dest][i];
        }
      }
    }
  }

  async createBankDetail(subSectionId, month, year) {
    let data = {
      bank_account_id: subSectionId,
      month: getMonthReverce(month),
      year,
    };

    let id = await fetchData('bank_account_details/create', data)
      .then(res => res.json())
      .then(res => {
        return res;
      });
    return id;
  }

  @action async uploadBankSubDocs() {
    let { files, dest, subSectionId, otherDealsCopy, additionalData } = this.uploadData;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const data = {
        parent_type: 'BankAccount',
        name: file.name,
        parent_id: additionalData.bankId.toString(),
        document_type: dest,
      };

      let document64 = (await this.toBase64(file)).split('base64,');
      data.document = document64[1];

      fetchData('documents/create', data)
        .then(res => res.json())
        .then(data => {
          RootModel.getBanks();

          if (i === files.length - 1) {
            RootModel.sortFilesByDate(subSectionId);
          }
          if (!otherDealsCopy)
            NotificationManager.success(`${file.title || file.name} has been successfully uploaded!`);
          this.onUploadSuccess && this.onUploadSuccess();
        })
        .catch(err => {
          NotificationManager.error(`Can not upload ${file.name}: ${err.message}`);
          UploadZipModel.setUnzipData();
          return;
        })
        .finally(() => {
          if (this.shouldReloadPage) {
            window.location.reload();
            this.setPageReloadState(false);
          }
        });

      if (i === files.length - 1) {
        RootModel.sortFilesByDate(subSectionId);
      }
    }
    UploadZipModel.setUnzipData();
  }

  @action async uploadBankDocuments(month, year) {
    this.setLoadingState(true);
    let { files, subSectionId, inZip, otherDealsCopy } = this.uploadData;
    let bankDateId = Helper.getBankDocumentsDateId(subSectionId, month, year);
    if (inZip || files.length > 1) DatePickerModel.toggleDatePicker();

    if (!bankDateId) {
      bankDateId = await this.createBankDetail(subSectionId, month, year);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const data = {
        parent_type: 'BankAccountDetail',
        name: file.name,
        parent_id: bankDateId,
      };

      let document64 = (await this.toBase64(file)).split('base64,');
      data.document = document64[1];
      if (!inZip && files.length === 1) {
        DatePickerModel.toggleDatePicker(subSectionId, false);
      }

      this.onUploadBegin && this.onUploadBegin();

      fetchData('documents/create', data)
        .then(res => res.json())
        .then(data => {
          RootModel.getBanks();

          if (i === files.length - 1) {
            RootModel.sortFilesByDate(subSectionId);
          }
          if (!otherDealsCopy)
            NotificationManager.success(`${file.title || file.name} has been successfully uploaded!`);
          this.onUploadSuccess && this.onUploadSuccess();
        })
        .catch(err => {
          NotificationManager.error(`Can not upload ${file.name}: ${err.message}`);
          UploadZipModel.setUnzipData();
          return;
        })
        .finally(() => {
          if (this.shouldReloadPage) {
            window.location.reload();
            this.setPageReloadState(false);
          }

          this.setLoadingState(false);
        });

      if (i === files.length - 1) {
        RootModel.sortFilesByDate(subSectionId);
      }
    }
    UploadZipModel.setUnzipData();
  }

  setUploadingData(file, dest, subSectionId) {
    let uploadingData = {};
    if (dest === 'FileBinFiles') {
      if (file.folderId || typeof subSectionId === 'number') {
        uploadingData.docType = 'General';
        uploadingData.parentType = 'Folder';
        uploadingData.parentId = file.folderId || subSectionId;
      } else {
        //create files in root folder
        const isUploadByThread = !DEAL_ID && THREAD_ID;
        uploadingData.docType = 'General';
        uploadingData.parentType = isUploadByThread ? 'Thread' : 'Deal';
        uploadingData.parentId = isUploadByThread ? THREAD_ID : DEAL_ID;
        if (isUploadByThread) {
          uploadingData.identity = THREAD_ID;
        }
      }
    } else {
      uploadingData.docType = Helper.isBankMonthToDate(dest, subSectionId)
        ? 'Month To Date'
        : this.convertDestinationToProp(dest, subSectionId);
      uploadingData.parentType =
        dest === 'Banks' && !Helper.isBankMonthToDate(dest, subSectionId) ? 'BankAccountDetail' : 'Deal';
      uploadingData.parentId = DEAL_ID;
    }

    uploadingData.fileName = file.name;
    if (uploadingData.fileName.indexOf('/') > -1) {
      uploadingData.fileName = uploadingData.fileName.split('/');
      uploadingData.fileName = uploadingData.fileName[uploadingData.fileName.length - 1];
    }

    return uploadingData;
  }

  @action uploadFiles = async isZip => {
    let { files, dest, subSectionId, otherDealsCopy } = this.uploadData,
      loadedFiles = [],
      filesInSubfolder = [],
      parentId,
      parentType,
      docType,
      fileName,
      isError = false,
      identity;
    if (!otherDealsCopy) {
      PopUpModel.open('notification');
      NotificationManager.info('Uploading started, wait a second please.', true);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      let uploadingData = this.setUploadingData(file, dest, subSectionId);
      parentId = uploadingData.parentId;
      parentType = uploadingData.parentType;
      docType = uploadingData.docType;
      fileName = uploadingData.fileName;
      identity = uploadingData.identity;

      const data = {
        //delete par_id for create/folder, upload
        parent_id: parentId,
        parent_type: parentType,
        document_type: docType,
        name: fileName,
      };

      if (identity) {
        data.identity = identity;
      }

      let document64 = (await this.toBase64(file)).split('base64,');
      data.document = document64[1];

      const uploadUrl = !DEAL_ID && THREAD_ID ? 'documents/create_thread' : 'documents/create';

      let fileId = await fetchData(uploadUrl, data)
        .then(res => res.json())
        .then(async data => {
          if (SECTION && SECTION.length) {
            window.location.reload(false);
            return;
          }

          let fileObj = { title: file.name };

          if (!file.folderId) loadedFiles.push(file);
          else if (file.folderId) {
            let path = file.name.split('/');
            let fileName = path[path.length - 1];
            path = path.slice(0, path.length - 1).join('/');
            NotificationManager.success(`${fileName} has been successfully uploaded to ${path}!`);
            this.onUploadSuccess && this.onUploadSuccess();
          }

          return data;
        })
        .catch(err => {
          if (file.folderId) {
            let path = file.name.split('/');
            let fileName = path[path.length - 1];
            path = path.slice(0, path.length - 1).join('/');
            NotificationManager.error(`Can not upload ${fileName} to ${path}: ${err.message}`);
          } else {
            NotificationManager.error(`Can not upload ${file.name}: ${err.message}`);
          }
          PopUpModel.closeAll();
          isError = true;
        })
        .finally(() => {
          if (this.shouldReloadPage) {
            window.location.reload();
            this.setPageReloadState(false);
          }
          this.setLoadingState(false);
        });
    }
    PopUpModel.closeAll();
    UploadZipModel.setUnzipData();
    parentType = subSectionId === 'FileBinFiles' ? 'Deal' : parentType;
    return !isError
      ? this.displayUploadedFiles(loadedFiles, parentType, dest, subSectionId, docType, isZip, otherDealsCopy)
      : null;
  };

  displayUploadedFiles(loadedFiles, parentType, dest, subSectionId, docType, isZip, otherDealsCopy) {
    if (isZip && subSectionId !== FileBinModel.activeFolderId) {
      // || !loadedFiles.length
      RootModel.getFolderDocuments(false, FileBinModel.activeFolderId, true);
    }

    let uploadedFiles,
      timeout,
      counter = 0;

    let timer = async () => {
      uploadedFiles = await this.getUploadedDocumentsData(loadedFiles, docType, parentType, dest, subSectionId);
      clearTimeout(timer);
      if (!isZip && uploadedFiles.length !== loadedFiles.length) {
        counter++;
        if (counter < 20) timeout = setTimeout(() => timer(), 800);
        else {
          NotificationManager.warning('Network error, files can not be displayed for now');
        }
      } else if (isZip || uploadedFiles.length == loadedFiles.length) {
        uploadedFiles.map((file, i) => {
          if (file) {
            let fileObj = Helper.convertToFileObject(file);
            if (dest === 'FileBinFiles') fileObj.folderId = subSectionId;
            FileManagerModel.addOneFile(fileObj, dest, subSectionId);
            NotificationManager.success(`${fileObj.title} has been successfully uploaded!`);
          }
        });
      }
    };
    UploadZipModel.setUnzipData();
    timer();
  }

  async getUploadedDocumentsData(files, docType, parentType, dest, subSectionId) {
    // BUG
    let newDocuments = [],
      subSectionDocuments;
    if (subSectionId == 'FileBinFiles' && subSectionId === FileBinModel.activeFolderId) {
      parentType = 'Deal';
    }
    do {
      newDocuments =
        parentType === 'Deal' ? await RootModel.getDocuments(true) : await RootModel.getFolderDocuments(true);
      subSectionDocuments =
        parentType === 'Deal' ? newDocuments.filter(doc => doc.document_type == docType) : [...newDocuments];

      if (subSectionDocuments.length > files.length) {
        newDocuments = [];
        for (let i = 0; i < files.length; i++) {
          let documents = subSectionDocuments.filter(
            sectionDoc => sectionDoc.original_filename == files[i].name || sectionDoc.title == files[i].name,
          );
          let filteredDocs = this.filterNewFiles(documents);
          newDocuments = newDocuments.concat(filteredDocs);
        }
        if (files.length === newDocuments.length) return newDocuments;
      } else if (subSectionDocuments.length === files.length) {
        return subSectionDocuments;
      }
    } while (files.length > subSectionDocuments.length);

    return newDocuments;
  }

  filterNewFiles(documents) {
    let { dest, subSectionId } = this.uploadData,
      newDocuments = [];
    let currentSubsectionFiles = Helper.getSectionFiles(dest, subSectionId);
    if (!currentSubsectionFiles) return documents;

    for (let i = 0; i < documents.length; i++) {
      if (currentSubsectionFiles.length) {
        let file = currentSubsectionFiles.find(currFile => documents[i].id.toString() === currFile.id.toString());
        if (!file) {
          newDocuments.push(documents[i]);
        }
      }
    }

    return newDocuments;
  }
} //END

const model = new UploadModel();
export default model;
