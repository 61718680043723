import React, {Component} from "react";
import {PREVIEW_UPLOAD_INFO} from "../../constants/api";
import DatePicker from "./DatePicker";
import UploadModel from "../../Models/loadModels/UploadModel";
import PopUpModel from "../../Models/PopUpModel";

class SingleFileUploadSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
        }
    }

    componentDidMount() {
        UploadModel.setOnUploadSuccess(this.sendMessage)
        UploadModel.setOnUploadBegin(this.setIsUploading)
    }

    setIsUploading = () => {
        this.setState({isUploading: true});
    }

    throttleSimplified = (func, ms) => {
        let isThrottled = false;
        let shouldRecall = false;

        function wrapper() {
            if (isThrottled) {
                shouldRecall = true;
                return;
            }

            func();

            isThrottled = true;

            setTimeout(function() {
                isThrottled = false;
                if (shouldRecall) {
                    wrapper();
                    shouldRecall = false;
                }
            }, ms);
        }

        return wrapper;
    };

    sendMessage = this.throttleSimplified(() => {
        if (!window.parent) return;
        const message = {uploadSuccess: true};
        window.parent.postMessage(message, '*');
    }, 500);

    onDragOver = (e) => {
        e.preventDefault()
    }

    onDrop = async (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        await UploadModel.uploadSingleFile(files[0], PREVIEW_UPLOAD_INFO.type,PREVIEW_UPLOAD_INFO.location, PREVIEW_UPLOAD_INFO.id);
    }

    render() {
        const { provided } = this.props;
        const {isUploading} = this.state;

        if (isUploading) {
            PopUpModel.open('loader');
        }

        if (UploadModel.uploadData && UploadModel.uploadData.isDatePickerShown && !isUploading) {
            return <DatePicker dest="Banks" subSectionData={UploadModel.uploadData} data-testid="date-picker"/>
        }

        return (
            <div 
                className={`upload-section`} 
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                ref={provided.innerRef}
                {...provided.droppableProps}
                data-testid="single-file-upload-section"
            >
                <input 
                    type="file"
                    onChange={e => UploadModel.uploadSingleFile(e.target.files[0], PREVIEW_UPLOAD_INFO.type, PREVIEW_UPLOAD_INFO.location, PREVIEW_UPLOAD_INFO.id)}
                    className="upload-section__input" 
                    data-testid="upload-input"
                />
                <span className="upload-section__text" data-testid="upload-text">
                    Drag {PREVIEW_UPLOAD_INFO.name} here<br />or click <span className="underline">here</span> to upload
                </span>
            </div>
        );
    }
}

export default SingleFileUploadSection;