import { API, userData } from '../constants/api';
// import axios from 'axios';

export default function fetchData (url, data) {
  let reqData = JSON.stringify({
      ...userData,
      ...data
    })

    return fetch(`${API}${url}`, {
    method: 'POST',
    body: reqData,
    headers: {
      'Content-Type': 'text/plain'
    },
    credentials: 'include'
  })

}
