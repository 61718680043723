import React, { Component } from 'react'
import { observer } from 'mobx-react';
import crossIcon from '../../Images/cross-white.png';

import PopUpModel from '../../Models/PopUpModel';
import TrashModel from '../../Models/TrashModel';

@observer class DeleteFileConfirmation extends Component {
    componentDidMount() {
        TrashModel.setOnDeleteSuccess(this.sendMessage)
    }

    throttleSimplified = (func, ms) => {
        let isThrottled = false;
        let shouldRecall = false;

        function wrapper() {
            if (isThrottled) {
                shouldRecall = true;
                return;
            }

            func();

            isThrottled = true;

            setTimeout(function() {
                isThrottled = false;
                if (shouldRecall) {
                    wrapper();
                    shouldRecall = false;
                }
            }, ms);
        }

        return wrapper;
    };

    sendMessage = this.throttleSimplified(() => {
        if (!window.parent) return;
        const message = {deleteSuccess: true};
        window.parent.postMessage(message, '*');
    }, 500);

    render() {
        const { fileData } = PopUpModel.deletableFileData;
        const isItFromTrash = TrashModel.isInTrash;
        //file or folder
        let isFile = fileData.hasOwnProperty('isEditModeOn') && fileData.hasOwnProperty('url') && fileData.hasOwnProperty('type');

        return (
            <div className="del-file-confirm" data-testid="delete-file-confirmation">
                <div className="del-file-confirm__close" onClick={e => PopUpModel.closeAll()} data-testid="close-popup"></div>
                <div className="del-file-confirm__inner">
                    <p className="head-section">
                        <span className="icon"><img src={crossIcon} alt=""/></span>
                        Delete File
                    </p>
                    {!isItFromTrash && <p className="message" data-testid="delete-message">{`Are you sure want to move this ${isFile ? 'file' : 'folder'} to trash?`}</p>}
                    {isItFromTrash && <p className="message" data-testid="delete-message">{`Are you sure you want to delete this ${isFile ? 'file' : 'folder'}?`}</p>}
                    <div className="options">
                        <button className="confirm-btn" onClick={e => PopUpModel.confirmDeleteFile()} data-testid="confirm-btn">
                          {(isItFromTrash) ? `Delete ${isFile ? 'File' : 'Folder'}` : 'Move to Trash'}
                        </button>
                        <span className="cancel-btn" onClick={e => PopUpModel.closeAll()} data-testid="cancel-btn">Cancel</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteFileConfirmation;
