import React from 'react';
import collapseIcon from '../../Images/arrow-collapse.png';

export default function CollapseBtn(props){
    return (
      <button className={`btn collapse-btn ${props.collapsed ? 'collapsed' : ''}`} onClick={props.onClick} data-testid="collapse-btn">
        <img src={collapseIcon} alt="arrow"/>
      </button>
    )
}
