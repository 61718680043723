import React from 'react';
import grayCrossIcon from '../../Images/cross-gray.png';
import whiteCrossIcon from '../../Images/cross-white.png';

export default function CloseBtn(props){
    return (
      <button className={`btn close-btn ${props.darkBackground ? 'close-bin' : ''}`} onClick={props.onClick} data-testid="close-button">
        <img src={props.darkBackground ? whiteCrossIcon : grayCrossIcon} className={props.darkBackground ? 'white-icon' : 'gray-icon'} alt="close" />
      </button>
    )
}
