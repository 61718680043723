import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx'
import folderIcon from '../../Images/blue-folder.png';
import otherFolderIcon  from '../../Images/gray-folder.png';

import DeleteBtn from '../buttons/DeleteBtn';
import EditBtn from '../buttons/EditBtn';
import CloseBtn from '../buttons/CloseBtn';

import PopUpModel from '../../Models/PopUpModel';
import FileBinModel from '../../Models/FileBinModel';

import EditFolderNameForm from '../forms/EditFolderNameForm'
import checkIcon from '../../Images/check-icon.png';
import Helper from '../../Helpers/helper';

@observer class FolderBlock extends Component {
    openEdit(e){
      const { data } = this.props;
      FileBinModel.toggleEditFolder(data);
    }

    getFolderData(){
      const { data } = this.props;
      FileBinModel.getSubFolders();
    }

    render() {
        const { data, isOtherDeals, isInTrash } = this.props;
        
        return (
          <div className={`folder ${this.props.className}`} onClick={() => this.getFolderData()} data-testid={`folder-block-${data.id}`}>
              <div onClick={this.props.onClick} className="click-panel" data-testid={`click-panel-${data.id}`}></div>
              {isOtherDeals ? 
                  <img className="folder-icon" src={otherFolderIcon} alt="folder" data-testid="other-folder-icon" /> : 
                  <img className="folder-icon" src={folderIcon} alt="folder" data-testid="folder-icon" />
              }
              <span data-testid={`folder-name-${data.id}`}>{isOtherDeals ? data.name : Helper.shortenName(data.name, 25)}</span>

              {!isOtherDeals && (
                <Fragment>
                  {!isInTrash && (
                    <DeleteBtn 
                      onClick={e => PopUpModel.open('deleteFileConfirmation', {
                        fileData: data,
                        dest: 'FileBinFolders'
                      })} 
                      data-testid={`delete-btn-${data.id}`}
                    />
                  )}

                  {!data.isEditModeOn && (
                    <EditBtn 
                      onClick={(data) => this.openEdit(data)}  
                      className={isInTrash ? 'trash-edit' : ''} 
                      data-testid={`edit-btn-${data.id}`}
                    />
                  )}
                </Fragment>
              )}

              {data.isEditModeOn && !isOtherDeals && (
                <React.Fragment>
                  <EditFolderNameForm folder={data} data-testid={`edit-folder-form-${data.id}`} />
                </React.Fragment>
              )}
          </div>
      );
    }
}

export default FolderBlock;
