import React from 'react';

class Loader extends React.Component {
  render() {
    return (
      <div className="overlay-loader">
        <div className="loader-content">
          <div className="spinner"></div>
          <p>File is uploading, please wait...</p>
        </div>
      </div>
    );
  }
}

export default Loader;
