import React from 'react';
import checkIcon from '../../Images/check-icon.png';

export default function SubmitBtn(props){
    return (
      <button 
        className={`btn submit-btn ${props.className}`} 
        onClick={props.onClick}
        data-testid="submit-btn"
      >
        <img src={checkIcon} alt="save changes"/>
      </button>
    )
}
