import React, { Component } from 'react';
import { observer } from 'mobx-react';
import UploadModel from '../../../Models/loadModels/UploadModel';
import FileBinModel from '../../../Models/FileBinModel';
import FilesSectionSingle from './FilesSectionSingle';
import FilesSectionViewFile from "./FilesSectionViewFile";

@observer class FileBinViewFile extends Component {

    onDragOver = (e) => {
      e.preventDefault()
    }

    onDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      UploadModel.handleFilesUpload(files, 'FileBinFiles', FileBinModel.activeFolderId);
    }

    render() {
      const { provided } = this.props;

      return (
        <div 
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="file-manager__container view_file_embed"
          data-testid="file-bin-view-file"
        >
          <div 
            className={`folder-section files-section wrapper folder-section-single-embed`}
            data-testid="folder-section-single-embed"
          >
            <FilesSectionViewFile data-testid="files-section-view-file"/>
          </div>
        </div>
      );
    }
}

export default FileBinViewFile;
