import React from 'react';

import collapseIcon from '../../Images/arrow-collapse.png';
import editIcon from '../../Images/edit-icon.png';
import fullPreviewIcon from '../../Images/full-preview-icon.png';
import prevBtn from '../../Images/prev-btn.png';
import nextBtn from '../../Images/next-btn.png';
import saveIcon from '../../Images/save-icon.png';
import checkIcon from '../../Images/check-icon.png';
import grayUploadIcon from '../../Images/gray-upload-icon.png';
import folderIcon from '../../Images/folder.png';
import resizeIcon from '../../Images/resize-icon.png'

const BUTTONS = {
  CollapseBtn: {
    icon: collapseIcon,
    className: 'collapse-btn',
    alt: 'Collapse Button'
  },
  EditBtn: {
    icon: editIcon,
    className: 'collapse-btn',
    alt: 'Edit Button'
  },
  FullPrevBtn: {
    icon: fullPreviewIcon,
    className: 'collapse-btn',
    alt: 'Full Preview Button'
  },
  PrevBtn: {
    icon: prevBtn,
    className: 'collapse-btn',
    alt: 'Preview Button'
  },
  NextBtn: {
    icon: nextBtn,
    className: 'collapse-btn',
    alt: 'Next Button'
  },
  SaveBtn: {
    icon: saveIcon,
    className: 'collapse-btn',
    alt: 'Save Button'
  },
  SubmitBtn: {
    icon: checkIcon,
    className: 'collapse-btn',
    alt: 'Submit Button'
  },
  UploadBtn: {
    icon: grayUploadIcon,
    className: 'collapse-btn',
    alt: 'Upload Button'
  },
  FileBinBtn: {
    icon: folderIcon,
    className: 'file-bit-btn',
    alt: 'File Bin Button'
  },
  ResizeBtn: {
    icon: resizeIcon,
    className: 'resize-btn',
    alt: 'Resize Button'
  }
}

export default function Button(props){
  const type = props.type;
    return (
      <div>
      <button className={`btn ${BUTTONS[type]['className']} ${props.collapsed ? 'collapsed' : ''}`} onClick={props.onClick} data-testid={`${type}-button`}>
        <img src={BUTTONS[type]['icon']} alt={BUTTONS[type]['alt']} />
      </button>
      </div>
    )
}