import React from 'react';
import arrowUpRightIcon from '../../Images/arrow-up-right.png';

export default function ExpandBtn(props){
    return (
      <button disabled={props.disabled} onClick={props.onClick} className={`btn expand-btn ${props.className}`} data-testid="expand-btn">
        <img src={arrowUpRightIcon} alt="expand" />
      </button>
    )
}
