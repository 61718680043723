import React from 'react';
import arrowUpIcon from '../../Images/arrow-up.png';

export default function DownloadBtn(props){
    return (
      <button disabled={props.disabled} onClick={props.onClick} className={`btn download-btn ${props.className}`} data-testid="download-btn">
        <img src={arrowUpIcon} alt="download" />
      </button>
    )
}
