import React from 'react';

import Banks from './Banks';
import SubDocs from './SubDocs';
import Contracts from './Contracts';
import Stips from './Stips';

function ViewAll(props){
    return (
        <div className="toolbar__container all" data-testid="view-all-container">
          <Banks viewAll={true} data-testid="view-all-banks"/> <div className="toolbar__container-divider" data-testid="divider-1"/>
          <SubDocs viewAll={true} data-testid="view-all-subdocs"/> <div className="toolbar__container-divider" data-testid="divider-2"/>
          <Stips viewAll={true} data-testid="view-all-stips"/> <div className="toolbar__container-divider" data-testid="divider-3"/>
          <Contracts viewAll={true} data-testid="view-all-contracts"/>
        </div>
    )
}

export default ViewAll;
