import React from 'react';
import editIcon from '../../Images/edit-icon.png';

export default function EditBtn(props){
    return (
        <button onClick={props.onClick} className={`btn edit-btn ${props.className}`} data-testid="edit-btn">
          <img src={editIcon} alt="edit" data-testid="edit-icon"/>
        </button>
    )
}
