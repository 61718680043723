import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autosize from 'autosize';
import checkIcon from '../../Images/check-icon.png';
import * as mobx from 'mobx'
import UpdateModel from '../../Models/UpdateModel';
import SubmitBtn from '../buttons/SubmitBtn';

export default @observer class EditFolderNameForm extends Component {
  constructor(props){
    super(props);
    let name = this.props.folder ? this.props.folder.name : null
    this.state = {
      name
    }
  }

    handleOnChange(e) {
      UpdateModel.setFolderName(e.target.value);
    }

    componentDidMount(){
      mobx.reaction(() => UpdateModel.folderName, folderName => {
        this.setState({
            name: folderName
        })
      })
    }

    render() {
      const {folder} = this.props,
            {name} = this.state;

            return (
              <div className="edit-name-form" data-testid="edit-folder-name-form">
                <textarea 
                  ref={node => this.textarea = node}
                  onChange={(e) => this.handleOnChange(e)}
                  className="text-field"
                  cols="15" 
                  rows="1"
                  placeholder="Please type a name here.." 
                  value={name}
                  data-testid="folder-name-input"
                />
                <SubmitBtn onClick={ () => UpdateModel.changeFolderName(name, folder) } data-testid="submit-btn" />
              </div>
            );
    }
}

// export default EditFolderNameForm;
