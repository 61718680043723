import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DevTools from 'mobx-react-devtools';
import { DragDropContext } from 'react-beautiful-dnd';
import { NotificationContainer, NotificationManager } from './popups/react-notifications/index';

import Toolbar from './containers/Toolbar';
import FileManager from './containers/FileManager';
import FileManagerCut from './containers/FileManagerCut';
import FileManagerSection from './containers/FileManagerSection';
import FileManagerViewFile from './containers/FileManagerViewFile';
import PopUpContainer from './containers/PopUpContainer';
import FullDocumentPreview from './documentPreview/FullDocumentPreview';
import FileManagerPreviewFile from './containers/FileManagerPreviewFile';

import RootModel from '../Models/RootModel';
import FileBinModel from '../Models/FileBinModel';
import DropEndModel from '../Models/DropEndModel';
import DocPreviewModel from '../Models/DocPreviewModel';
import PopUpModel from '../Models/PopUpModel';
import OtherDealsModel from '../Models/OtherDealsModel';
import UploadModel from '../Models/loadModels/UploadModel';
import * as mobx from 'mobx';

import {
  userData,
  DEAL_ID,
  THREAD_ID,
  SECTION,
  VIEW_FILE,
  VIEW_PREVIEW,
  FULL_PAGE,
  MERCHANT_ID,
  LOAD_TYPE,
} from '../constants/api';

import '../Styles/index.scss';
import Loader from './loader/Loader';

@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.disposer = null;
    this.state = {
      init: true, //We dont want to show the loader if we have a view file.
      cutVersion: LOAD_TYPE === 'thread',
      section: SECTION,
      viewFile: VIEW_FILE,
      viewPreview: VIEW_PREVIEW,
      fullPage: FULL_PAGE,
    };

    if (!userData || !userData.hasOwnProperty('user_id') || !LOAD_TYPE) {
      PopUpModel.open('error', 'Failed: No User Data');
    }
  }

  handleDragEnter = event => {
    let elem = event.target;
    do {
      if (elem.className && elem.className.indexOf('subsection') > -1) {
        elem.className += ' dragenter';
        break;
      }
      if (elem.parentNode) elem = elem.parentNode;
    } while (elem.className !== 'App' && elem.parentNode);
  };

  handleDragLeave = event => {
    let elem = event.target;
    do {
      if (elem.className && elem.className.indexOf('subsection') > -1) {
        let classes = elem.className.split(' ');
        classes.splice(
          classes.findIndex(c => c === 'dragenter'),
          1,
        );
        elem.className = classes.join(' ');
        break;
      }
      if (elem.parentNode) elem = elem.parentNode;
    } while (elem.className !== 'App' && elem.parentNode);
  };

  handleMouseOut = event => {
    let elems = document.getElementsByClassName('dragenter');
    if (elems.length) {
      for (let i = 0; i < elems.length; i++) {
        let classes = elems[i].className.split(' ');
        classes.splice(
          classes.findIndex(c => c === 'dragenter'),
          1,
        );
        elems[i].className = classes.join(' ');
      }
    }
  };

  componentDidMount() {
    document.addEventListener('dragenter', this.handleDragEnter);

    document.addEventListener('dragleave', this.handleDragLeave);

    document.addEventListener('mouseout', this.handleMouseOut);

    if (this.state.init && userData && userData.hasOwnProperty('user_id')) {
      if (SECTION && SECTION.length) {
        RootModel.getDocuments();
        if (SECTION !== 'General') {
          FileBinModel.getFolders(true);
        }
        this.setState({ init: false });
      } else if (FULL_PAGE) {
        RootModel.getDocuments();
        FileBinModel.getFolders(true);
        this.setState({ init: false });
      } else if (VIEW_PREVIEW) {
        RootModel.getBanks(true);
        RootModel.getDocuments();
      } else if (DEAL_ID) {
        if (!VIEW_FILE) {
          RootModel.getBanks(true);
          FileBinModel.getFolders(true);
          OtherDealsModel.getOtherDealsDocuments();
        }
        RootModel.getDocuments();
        this.setState({ init: false });
      } else if (THREAD_ID || MERCHANT_ID) {
        RootModel.getDocuments();
        this.setState({ init: false });
      }
    }

    this.disposer = mobx.autorun(() => {
      const { fullPreview } = DocPreviewModel,
        { activePopUp } = PopUpModel;

      this.setState({ fullPreview, activePopUp });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('dragenter', this.handleDragEnter);

    document.removeEventListener('dragleave', this.handleDragLeave);

    document.removeEventListener('mouseout', this.handleMouseOut);

    this.disposer();
  }

  onDragEnd = result => {
    DropEndModel.handleDropEnd(result);
  };

  render() {
    const { activePopUp, fullPreview, cutVersion, section, fullPage, viewFile, viewPreview } = this.state;

    if (!userData || activePopUp === 'error') return <PopUpContainer />;

    if (viewPreview) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <PopUpContainer />
          <FileManagerPreviewFile />
        </DragDropContext>
      );
    } else if (viewFile && viewFile.length) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <PopUpContainer />
          <FileManagerViewFile />
        </DragDropContext>
      );
    }

    if (section && section.length) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <PopUpContainer />
          <FileManagerSection />
        </DragDropContext>
      );
    }

    if (fullPage) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <PopUpContainer />
          <FileManagerSection />
        </DragDropContext>
      );
    }

    if (cutVersion) {
      return (
        <div className="App">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <PopUpContainer />
            <FileManagerCut />
          </DragDropContext>
          <NotificationContainer />
        </div>
      );
    }

    return (
      <div className="App">
        {fullPreview && <FullDocumentPreview />}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Toolbar />
          <PopUpContainer />
          <FileManager />
        </DragDropContext>
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
