import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import PopUpModel from '../../Models/PopUpModel';
import * as mobx from 'mobx';

import DownloadBtn from '../buttons/DownloadBtn';
import CollapseBtn from '../buttons/CollapseBtn';

import ToolbarSubsection from '../ToolbarSubsection';
import RootModel from '../../Models/RootModel';
import NavigationModel from '../../Models/NavigationModel';
import UploadModel from '../../Models/loadModels/UploadModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';

import Helper from '../../Helpers/helper';
import collapseIcon from '../../Images/arrow-collapse.png';
import stipsIcon from '../../Images/stips-icon.png';

@observer class Stips extends Component {
    constructor(props){
      super(props);

      this.disposer = null;
      this.state = {
        contracts: null
      }
    }

    componentDidMount(){
      this.disposer = mobx.autorun(() => {
        const collapsed = this.props.viewAll ? NavigationModel.viewAllCollapsedSections.Stips : NavigationModel.collapsedSections.Stips;
        const stips = RootModel.Stips;
        let isEmpty;
        if(stips.length){
          isEmpty = Helper.isEmptySection(stips);
        }

        this.setState({stips, isEmpty, collapsed});
      });
    }

    componentWillUnmount(){
      this.disposer();
    }

    render() {
      const {stips, isEmpty, collapsed} = this.state;
      if(!stips) return null;

        return (
            <div className={`toolbar__container stips ${collapsed ? 'container-collapsed' : ''}`} data-testid="stips-container">
                <div className="head-section" data-testid="stips-head-section">
                    <p className="title" data-testid="stips-title">
                      <CollapseBtn collapsed={collapsed} onClick={(e, viewAll) => NavigationModel.handleSectionCollapse('Stips', this.props.viewAll)} />
                        <img src={stipsIcon} className="icon" alt="stips" />
                        Stips
                        <DownloadBtn disabled={isEmpty} onClick={() => DownloadModel.downloadSubsectionFilesInZip('Stips')} data-testid="download-stips-btn"/>
                    </p>
                </div>
                {!collapsed && stips.map(subsection => <React.Fragment key={subsection.id} data-testid={`stips-subsection-${subsection.id}`}>
                    <Droppable droppableId={subsection.id}>
                        {provided => <ToolbarSubsection provided={provided} dest="Stips" key={subsection.id} data={subsection}/>}
                    </Droppable>
                </React.Fragment>)}
            </div>
        )
    }
}

export default Stips;
