import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import UploadZipModel from '../../Models/loadModels/UploadZipModel.js';
import PopUpModel from '../../Models/PopUpModel';

@observer class UnzipConfirm extends Component{// OPTIMIZE: rewrite to h
  state = {};
  disposer = null;

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const {unzipData} = UploadZipModel;
      this.setState({unzipData})
    })
  }

  componentWillUnmount(){
    this.disposer();
  }

  render(){
    const {unzipData} = this.state;
    if(!unzipData) return null;
    return (
      <div className="zip-confirm" data-testid="unzip-confirmation">
        <div className="zip-confirm__inner">
          <p data-testid="confirmation-message">{(unzipData && unzipData.isFolderInZip && unzipData.dest !== 'FileBinFiles') ?
             'This file has folders and cannot be unpacked. Would like to upload the entire zip file?' :
             'Would you like to unpack the contents of this file?'}</p>
          <div className="zip-confirm__inner-buttons-container">
            <button className="button-confirm" onClick={(e) => UploadZipModel.unzipConfirm(e, true)} data-testid="confirm-btn">Yes</button>
            <button className="button-deny" onClick={(e) => UploadZipModel.unzipConfirm(e, false)} data-testid="deny-btn">No</button>
          </div>
        </div>
      </div>
    );
  }
}

export default UnzipConfirm;
