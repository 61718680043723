import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import PopUpModel from '../../Models/PopUpModel';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import uuid from 'uuid';
import DownloadBtn from '../buttons/DownloadBtn';
import ToolbarSubsection from '../ToolbarSubsection';
import RootModel from '../../Models/RootModel';
import NavigationModel from '../../Models/NavigationModel';
import UploadModel from '../../Models/loadModels/UploadModel';
import ToolbarFileSection from '../ToolbarFileSection';

import collapseIcon from '../../Images/arrow-collapse.png';
import contractsIcon from '../../Images/contracts-icon.png';

import { formatDate } from '../../Helpers/convertData';

@observer class OtherDealsSection extends Component {
  constructor(props){
    super(props);

    this.dropRef = React.createRef();

    let sortedDeals = this.groupFilesByDate();

    this.disposer = null;
    this.state = {
      collapsed: true,
      sortedDeals
    }
  }

  groupFilesByDate(){
    const { otherDeals } = this.props.data;
    let sortedDeals = [];

    for (var i = 0; i < otherDeals.length; i++) {
      let formatedDate = formatDate(otherDeals[i].submission_date);

      if( !(formatedDate in sortedDeals) ){

        let date = otherDeals.filter(deal => {
          return formatDate(deal.submission_date) == formatedDate;
        });
        sortedDeals[formatedDate] = date;

        let fragment = (<Fragment key={uuid.v4()}>
          <h4 className="other-deals-date">{formatedDate}</h4>
            {date.map((file, index) => {
              return <Draggable key={file.id} draggableId={file.id} index={index}>
                    {(provided, snapshot) => (
                      <ToolbarFileSection isOtherDeal={true} provided={provided} title={this.props.data.name}
                                          isDeletable={this.props.data.isDeletable} dest={this.props.dest}
                                          subSectionId={this.props.data.id} key={file.id} data={file}/>
                    )}
                  </Draggable>
                })
          }
        </Fragment>);

        sortedDeals.push(fragment);
      }
    }

    return sortedDeals;
  }

  toggleSection = (e) => {
    this.setState({collapsed: !this.state.collapsed})
  }

  render() {
    const { data } = this.props,
          { sortedDeals, collapsed } = this.state;

    if(!sortedDeals) return null;

    return (
      <div className={`subsection__other-deals ${this.state.collapsed ? 'deals-collapsed' : ''}`} data-testid="other-deals-section">
          <div className="subsection__other-deals__header" data-testid="other-deals-header">
              <img onClick={(e) => this.toggleSection(e)}
                   className={`collapse-btn ${collapsed ? 'deals-collapsed' : ''}`} src={collapseIcon} alt="arrow" data-testid="collapse-btn"/>
               <h4 data-testid="other-deals-title">{data.title} From Other Deals</h4>
             </div>
            {!collapsed && <Droppable droppableId={`other-${uuid.v4()}`}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                data-testid="droppable-other-deals"
              >
                {this.state.sortedDeals}
              </div>
            )}
            </Droppable>
        }
      </div>
    )
  }
}

export default OtherDealsSection;
// <h4 className="other-deals-date">{formatDate(file.submission_date)}</h4>
