import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import App from './Components/App';

configure({ enforceActions: 'observed' });

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();


window['__react-beautiful-dnd-disable-dev-warnings'] = true; // TURNING OFF DEVELOPMENT CONSOLE NOTIFICATIONS FOR DRAG AND DROP
