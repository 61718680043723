import React from 'react';

class FileLoader extends React.Component {
  render() {
    return (
      <div className="file-loader-container">
        <div className="file-loader-spinner"></div>
        <p>File is uploading...</p>
      </div>
    );
  }
}

export default FileLoader;
