import React from 'react';
import crossIcon from '../../Images/cross-white.png';

export default function DeleteBtn(props){
    return (
        <button onClick={props.onClick} className={`btn delete-btn ${props.className}`}
            style={{
                width: '24px',
                height: '24px'
            }}
            data-testid="delete-btn"
        >
            {props.isPreview ? 
              <svg className="delete-btn-icon" width="17" height="22" viewBox="0 0 17 22" fill="#fff" xmlns="http://www.w3.org/2000/svg" data-testid="delete-icon-svg">
                <path fillRule="evenodd" fill="#fff" className="trash-svg" clipRule="evenodd" d="M2.93703 20.5068C2.93703 20.5068 3.24983 22 4.8599 22H12.1399C13.7499 22 14.0627 20.5068 14.0627 20.5068L15.4999 5.8667H1.49991L2.93703 20.5068ZM11.2999 8.06673C11.2999 7.66168 11.6133 7.33341 11.9999 7.33341C12.3865 7.33341 12.7 7.66168 12.7 8.06673L11.9999 19.0667C11.9999 19.4718 11.6865 19.8 11.2999 19.8C10.9134 19.8 10.5999 19.4717 10.5999 19.0667L11.2999 8.06673ZM7.79993 8.06673C7.79993 7.66168 8.11335 7.33341 8.49991 7.33341C8.88655 7.33341 9.19989 7.66168 9.19989 8.06673V19.0667C9.19989 19.4718 8.88655 19.8 8.49991 19.8C8.11327 19.8 7.79993 19.4717 7.79993 19.0667V8.06673ZM4.99991 7.33333C5.38655 7.33333 5.69989 7.6616 5.69989 8.06665L6.39996 19.0666C6.39996 19.4717 6.08653 19.8 5.69989 19.8C5.31334 19.8 4.99991 19.4716 4.99991 19.0666L4.29993 8.06673C4.29993 7.66168 4.61335 7.33333 4.99991 7.33333ZM15.0799 2.9345H11.9999V1.46672C11.9999 0.354162 11.6564 0 10.5999 0H6.39988C5.43082 0 4.99991 0.491746 4.99991 1.46672V2.93458H1.91995C1.30092 2.93458 0.799927 3.42682 0.799927 4.03509C0.799927 4.64344 1.30092 5.13568 1.91995 5.13568H15.0799C15.6989 5.13568 16.1999 4.64344 16.1999 4.03509C16.1999 3.42682 15.6989 2.9345 15.0799 2.9345ZM10.5999 2.9345H6.39996L6.40004 1.46663H10.6V2.9345H10.5999Z" fill="#fff"/>
              </svg>
            : 
              <img className='delete-btn-img' src={crossIcon} alt="cross"
                  style={{
                    width: '12px',
                    height: '12px'
                  }}
                  data-testid="delete-icon-img"
                />}
        </button>
    )
}
