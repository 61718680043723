import React, { Component } from 'react';
import { observer } from 'mobx-react';

import FileBinModel from '../../Models/FileBinModel';
import PopUpModel from '../../Models/PopUpModel';

import { NotificationManager } from '../popups/react-notifications/index';


@observer class AddFolderForm extends Component {
  state = {
    fileName: ''
  }

  handleChange(e){
    this.setState({fileName: e.target.value})
  }

  submitAddFolderForm(fileName){
    if(!fileName){
      NotificationManager.warning('This field can not be empty');
    }else{
      FileBinModel.submitAddFolderForm(fileName)
    }
  }

    render() {
      const {fileName} = this.state;

        return (
            <div className="add-folder-form" data-testid="add-folder-form">
              <div onClick={e => {PopUpModel.closeAll()}} className="add-folder-form__close" data-testid="close-popup"></div>
              <div className="add-folder-form__inner">
                <label htmlFor="folderName">Folder Name</label>
                <input type="text" id="folderName" onChange={(e) => this.handleChange(e)} data-testid="folder-name-input"/>

                <button onClick={e => this.submitAddFolderForm(fileName)}
                        className="submit" data-testid="submit-btn">Save</button>
                <span onClick={e => { PopUpModel.closeAll() }} className="cancel-btn" data-testid="cancel-btn">Cancel</span>
              </div>
            </div>
        )
    }
}

export default AddFolderForm;
