import React, {Component} from 'react';
import {observer} from 'mobx-react';
import * as mobx from 'mobx';

import FileBlockCut from '../FileBlockCut';
import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import DocPreviewModel from '../../../Models/DocPreviewModel';
import FileBinModel from '../../../Models/FileBinModel';
import uploadIcon from "../../../Images/add-icon-gray.png";
import {FULL_PAGE, SECTION} from "../../../constants/api";
import FullPageDocuments from "../fullPageDocuments/FullPageDocuments";
import UploadModel from "../../../Models/loadModels/UploadModel";


@observer
class FilesSectionSingle extends Component {
  constructor(props) {
    super(props);

    this.disposer = null;
    this.uploadInputRef = React.createRef();

    this.state = {
      isInTrash: false,
      fileBinFiles: [],
      activeFolderId: 'FileBinFiles'
    }
  }

  openFileDialog = () => {
    this.uploadInputRef.current.click();
  }

  uploadFiles = (e) => {
    let folder = RootModel.SubSectionMap[SECTION.toLowerCase()];
    const files = e.target.files;
    UploadModel.handleFilesUpload(files, folder.section, folder.info.id, false, true);
  }


  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const activeFolderId = FileBinModel.activeFolderId;

      const fileBinFiles = [...RootModel.FileBinFiles].filter(file => {
            if (file.parent_id) {
              return file.parent_id.toString() === activeFolderId.toString()
            }
            return false
          }),
          activeDocData = DocPreviewModel.activeDocData,
          isInTrash = TrashModel.determineIsInTrash();

      this.setState({activeFolderId, fileBinFiles, activeDocData, isInTrash});
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const {activeDocData, fileBinFiles, isInTrash, inOtherDeals} = this.state;

    if (FULL_PAGE) {
      const files = {
        'Signed_contract_coj': {
          label: 'Signed Contract',
          items: []
        },
        'General': {
          //Do do we need to make sure we are getting the correct document from here. I can work on this
          label: 'Funding Account',
          items: []
        },
        'Funding_vc': {
          label: 'Voided Check',
          items: []
        },
        'App': {
          label: 'Application',
          items: []
        },
        'Dl': {
          label: 'Drivers License',
          items: []
        },
        'Credit': {
          label: 'Credit Report',
          items: []
        },
        'Sos': {
          label: 'SOS',
          items: []
        },
        'Funding_call': {
          label: 'Funding Call',
          items: []
        },
        'Certificate': {
          label: 'Additional Funding Files',
          items: []
        }
      }
      fileBinFiles.forEach((file) => {
        if (!files[file.document_type]) {
          return;
        }
        files[file.document_type].items.push(file)
      })
      return <div className="allFilesPage" data-testid="all-files-page">{
        Object.keys(files).map((category) => {
          if (category === 'General') return null
          if (category === 'Funding_vc') {
            return <div key={category} style={{display: 'flex', columnGap: '13px'}} data-testid={`category-${category}`}>
              <FullPageDocuments {...{
                category: 'General',
                uploadBtn: false,
                activeDocData,
                inOtherDeals,
                isInTrash,
                files
              }} />
              <FullPageDocuments {...{category, files, activeDocData, isInTrash, inOtherDeals}} />
            </div>
          }
          return <FullPageDocuments {...{
            key: category,
            activeDocData,
            inOtherDeals,
            isInTrash,
            category,
            files
          }} data-testid={`category-${category}`} />

        })
      }</div>
    }

    return (<>
      {fileBinFiles.length > 0 &&
          fileBinFiles.map((file) => {
                let isPreviewed = activeDocData ? (file.id.toString() === activeDocData.id.toString()) : null;
                return <FileBlockCut key={file.id}
                                     data={file}
                                     isPreviewed={isPreviewed}
                                     isInTrash={isInTrash}
                                     inOtherDeals={inOtherDeals}
                                     fileClassName={"file file-embed"}
                                     hideDownloadBtn={true}
                                     data-testid={`file-${file.id}`}
                />
              }
          )}
      <div className="container" data-testid="upload-container">
        <span className="icon" onClick={this.openFileDialog} data-testid="upload-icon">
          <img src={uploadIcon} alt="upload"/>
        </span>
        <input 
          style={{display: "none"}} 
          multiple 
          type="file"
          onChange={e => this.uploadFiles(e)}
          ref={this.uploadInputRef}
          data-testid="upload-input"
        />
      </div>
    </>)
  }
}

export default FilesSectionSingle;
